import ReactGA from "react-ga";

export const initGA = () => {
  ReactGA.initialize("UA-178922322-1");
};

export const logPageView = () => {
  ReactGA.set({page: window.location.pathname});
  ReactGA.pageview(window.location.pathname);
};

export const logEvent = ({category = "", action = "", label, nonInteraction}) => {
  if (category && action) {
    ReactGA.event({
      category,
      action,
      ...(label && {label}),
      ...(nonInteraction && {nonInteraction}),
    });
  }
};

export const logException = (description = "", fatal = false) => {
  if (description) {
    ReactGA.exception({description, fatal});
  }
};
