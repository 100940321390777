import App from "next/app";
import Router from "next/router";
import {initGA, logPageView} from "../utils/analytics";
import {UserContextProvider} from "../context/userContext";

import '../css/global.css'

export default class MyApp extends App {
  componentDidMount() {
    initGA();
    logPageView();
    Router.events.on("routeChangeComplete", logPageView);
  }

  render() {
    const {Component, pageProps} = this.props;
    // Workaround for https://github.com/zeit/next.js/issues/8592
    const {err} = this.props;
    const modifiedPageProps = {...pageProps, err};

    return (
      <UserContextProvider>
        <Component {...modifiedPageProps} />
      </UserContextProvider>
    );
  }
}